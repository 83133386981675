<template>
  <ClientOnly v-if="$preferences?.lastVisitedEnabled">
    <LazyHydrationWrapper when-idle>
      <ProductSlider
        :products="products"
        :loading="loading"
        :title="$t('product.recently.viewed')"
        @product:clicked="handleProductClick"
      />
    </LazyHydrationWrapper>
  </ClientOnly>
</template>

<script lang="ts" setup>
import type { AgnosticProduct } from 'shared/types'

const props = defineProps<{
  product?: AgnosticProduct
}>()

const { products, loading, setVisited } = useLastVisited()
// const { reportProductImpressions, reportProductClick } = useTagManager()

const getProducts = () => {
  if (props.product?.id) {
    return setVisited(props.product)
  }
}

const loadProducts = async () => {
  await getProducts()
  // reportProductImpressions(products.value, 'Products Carousel')
}

watchEffect(loadProducts)

const handleProductClick = (/* payload: { product: AgnosticProduct; position: number } */) => {
  // reportProductClick(payload.product, 'Products Carousel', { position: payload.position })
}
</script>
