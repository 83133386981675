<template>
  <WishlistIcon
    :selected="isInWishlist(product)"
    :static-aria-text="`${$t('wishlist.phrase')}`"
    :loading="loading"
    @click="toggleWishlist"
  />
</template>
<script lang="ts" setup>
import type { ActionError, AgnosticProduct, AgnosticProductTile, AgnosticVariant } from 'shared/types'

const props = defineProps<{
  product: AgnosticProductTile | AgnosticProduct | AgnosticVariant
}>()
const { toggleProduct, loading, isInWishlist, error } = useWishlist()

const { t } = useI18n()
const { send: notify } = useUiNotification()

const wishlistError = computed<ActionError | undefined>(() => {
  return error.value.add || error.value.remove
})

const toggleWishlist = async () => {
  const isIn = isInWishlist(props.product)
  await toggleProduct(props.product)

  if (!wishlistError.value) {
    notify(`toggleWishlist-${isIn ? 'removed' : 'added'}`, {
      message: isIn ? t('notifications.wishlistRemoved') : t('notifications.wishlistAdded')
    })
  } else {
    notify('toggleWishlist', {
      type: 'error',
      message: t(wishlistError.value.__general__)
    })
  }
}
</script>
