<template>
  <AsfRating
    v-if="$preferences.ratingEnabled"
    :id="product.id"
    :link="link"
    :count="count"
    :score="score"
    :is-link-message="isLinkMessage"
    :is-anchor="isAnchor"
  />
</template>
<script lang="ts" setup>
import type { AgnosticProduct, AgnosticProductTile } from 'shared/types'
import type { AsfRatingProps } from '@ui/types'

type ProductRatingsProps = {
  product: AgnosticProduct | AgnosticProductTile
  link: AsfRatingProps['link']
  isAnchor?: AsfRatingProps['isAnchor']
  isLinkMessage?: AsfRatingProps['isLinkMessage']
}

const props = withDefaults(defineProps<ProductRatingsProps>(), {
  link: ''
})

const score = computed(() =>
  'properties' in props.product ? parseFloat(props.product.properties?.reviewAverageRating?.value ?? '') || 0 : 0
)
const count = computed(() =>
  'properties' in props.product ? parseFloat(props.product.properties?.reviewCount?.value ?? '') || 0 : 0
)
</script>
