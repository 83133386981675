<template>
  <div
    v-e2e="'ta-product-tile'"
    class="asf-product-tile"
    :class="{ 'is-sold-out': product.oos }"
    :aria-label="`${$t('accessibility.group.plp.productTile')}`"
    role="group"
    tabindex="-1"
  >
    <AsfLink :link="link">
      <div class="asf-product-tile__top">
        <div
          ref="imageWrapper"
          v-e2e="'image-product-tile'"
          class="asf-product-tile__image-wrapper"
          :class="{
            'is-alternate-active': isAlternateImageActive,
            'm-image-alternate': image.isAlternate
          }"
          @scroll="handleImageScroll"
        >
          <AsfImage
            :title="image.main.title"
            :alt="image.main.alt"
            :src="image.main.src"
            :width="image.main.width"
            :height="image.main.height"
            :adaptive="image.main.adaptive"
            :lazy="lazy"
            :fetch-priority="fetchPriority"
            class="asf-product-tile__image"
          />
          <AsfImage
            v-if="image.isAlternate"
            :src="image.alternate.src"
            :alt="image.alternate.src"
            :adaptive="image.alternate.adaptive"
            :lazy="lazy"
            :fetch-priority="fetchPriority"
            class="asf-product-tile__image is-alternate"
          />
        </div>
      </div>
      <div v-if="product.oos" class="asf-product-tile__sold-out">
        {{ $t('product.soldOut') }}
      </div>
      <AsfHeading v-if="product.name" tag="h2" class="asf-product-tile__heading">
        {{ product.name }}
      </AsfHeading>
    </AsfLink>

    <AsfPrice :prices="product.prices" />

    <AsfPromotion v-for="promotion in product?.promotions" :key="promotion.id" :discount-msg="promotion.msg" />

    <div v-if="colorSwatches.shown.length" class="asf-product-tile__variations">
      <AsfVariationSwatch
        v-for="variant in colorSwatches.shown"
        :key="variant.value"
        v-bind="variant"
        :selected="variant.selected"
        :no-swatches-highlighter="noSwatchesHighlighter"
        :product-id="product.id"
      />
      <AsfLink
        v-if="colorSwatches.hidden.length"
        class="asf-product-tile__variations-all"
        :title="$t('global.showAll')"
        :aria-label="$t('global.showAll')"
        :link="link"
      >
        +{{ colorSwatches.hidden.length }}
      </AsfLink>
    </div>

    <slot name="ratings" :ratings-product="product" :ratings-link="link" />
    <slot name="wishlist-icon" :wishlist-product="product" />
  </div>
</template>
<script lang="ts" setup>
import { AgnosticBaseFacetAttrs } from 'shared/types'
import type { AsfProductTileProps, AsfVariationSwatchProps } from '@ui/types'
import type { AsfLink } from '#components'

const props = withDefaults(defineProps<AsfProductTileProps>(), {
  visibleSwatchCount: MAX_MD_VARIATION_SWATCH_COUNT,
  noSwatchesHighlighter: false,
  lazy: true,
  fetchPriority: 'high'
})
const { getProductURL } = useURL()

const colorSwatches = computed(() => {
  if (!props.product.swatches) {
    return { shown: [], hidden: [] }
  }

  const allColorSwatches: AsfVariationSwatchProps[] =
    props.product.swatches.map(function allColorSwatches(ca, idx) {
      return {
        type: 'color',
        name: ca.name || '',
        value: ca.value,
        selected: idx === 0,
        link: getProductURL(props.product, [{ name: AgnosticBaseFacetAttrs.color, value: ca.value }])
      }
    }) || []

  const shown = allColorSwatches.slice(0, props.visibleSwatchCount)
  const hidden = allColorSwatches.slice(shown.length)

  return { shown, hidden }
})

// TODO: @i.khartov, please refactor this logic.
const isAlternateImageActive = ref(false)

const image = computed(() => {
  const alternateImage = {
    src: '',
    alt: props.product.name,
    adaptive: true
  }
  return {
    alternate: alternateImage,
    isAlternate: Boolean(alternateImage.src),
    main: {
      src: '',
      alt: props.product.name,
      title: props.product.name,
      width: 276,
      height: 368,
      adaptive: true,
      ...(props.product.images.at(0) || {})
    }
  }
})

const imageWrapper = ref<InstanceType<typeof AsfLink> | null>(null)

const handleImageScroll = () => {
  if (!image.value.isAlternate || !imageWrapper.value) return
  const element = imageWrapper.value.$el

  if (element) {
    isAlternateImageActive.value = element.scrollLeft > element.clientWidth / 2
  }
}
const link = computed(() => getProductURL(props.product))
</script>
<style lang="postcss">
@import '@components/molecules/ProductTile/ProductTile.css';
</style>
