import type { ActionError, AgnosticProductTile, AgnosticProductTilesSearchParams } from 'shared/types'
const logger = Logger.getLogger({ scope: 'agnostic', method: 'useProduct' })

export function useTiles(id: string) {
  const ssrKey = 'useTiles'
  const products: Ref<readonly AgnosticProductTile[]> = ref([])
  const loading = ref<boolean>(false)
  const error = ref<{ search?: ActionError }>({})
  const api = useEcommerce()

  const search = async (searchParams: AgnosticProductTilesSearchParams) => {
    logger.debug(`${ssrKey}/${id}/search`, searchParams)

    try {
      loading.value = true
      const result = await api.getTiles(searchParams)
      if (result.ok) {
        products.value = result.payload
        error.value.search = undefined
      } else {
        error.value.search = result.error
      }
    } catch (err) {
      error.value.search = {
        __general__: String(err)
      }
      logger.error(`${ssrKey}/${id}/search`, err)
    } finally {
      loading.value = false
    }
  }

  return {
    search,
    products: computed(() => products.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  }
}
