<template>
  <AsfLink
    class="asf-rating"
    :link="link"
    :is-anchor="isAnchor"
    :title="$t('rating.label', { count: finalScore })"
    :aria-label="$t('rating.label', { count: finalScore })"
  >
    <AsfIcon
      v-for="n in 5"
      :id="`${id}-${n}`"
      :key="n"
      name="star"
      size="4"
      class="asf-rating__icon"
      :class="{
        'is-star-filled': n <= finalScore,
        'is-star-empty': n > Math.ceil(finalScore),
        'is-star-half': !(n <= finalScore || n > Math.ceil(finalScore))
      }"
    />
    <span class="asf-rating-number">{{ isLinkMessage ? $t('product.reviews', { count }) : `(${count})` }}</span>
  </AsfLink>
</template>
<script lang="ts" setup>
import type { AsfRatingProps } from '@ui/types'
const props = withDefaults(defineProps<AsfRatingProps>(), {
  score: 0,
  count: 0
})
const finalScore = computed(() => {
  if (!props.score || props.score < 0) {
    return 0
  } else if (props.score > 5) {
    return 5
  } else {
    return props.score
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/Rating/Rating.css';
</style>
